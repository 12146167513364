.da-dwa-list {
	display: block;
	box-shadow: 0 1.6px 3.6px 0 rgba(0,0,0,.132), 0 0.3px 0.9px 0 rgba(0,0,0,.108);
}

.da-dwa-list .ms-Viewport .ms-DetailsHeader {
	padding-top: 1px;
}

.da-dwa-list .ms-Viewport .ms-DetailsRow {
	cursor: pointer;
}