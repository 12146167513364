.da-dwa-page {
	display: flex;
	flex-direction: column;
	height: 100%;
	flex: auto;
	overflow-y: scroll;
	padding-bottom: 40px;
	box-sizing: border-box;
	position: relative;
}

.da-logs-container {
	background-color: var(--white);
	box-shadow: 0 1.6px 3.6px 0 rgba(0,0,0,.132), 0 0.3px 0.9px 0 rgba(0,0,0,.108);
	display: flex;
	flex-direction: column;
}

.da-logs-fallback {
	padding: 2em;
}