.da-main {
	display: flex;
	flex-direction: column;
	height: 100%;
	position: relative;
}

.da-main > main {
	display: flex;
	flex-direction: row;
	align-items: stretch;
	flex: auto;
	overflow: hidden;
	position: relative;
	z-index: 1;
}

.da-main > main .da-sidebar {
	display: none;
	flex-direction: column;
	min-width: 14.286em;
	border-right: 1px solid var(--neutralLight);
	overflow-y: auto;
}

@media screen and (min-width: 1024px) {
	.da-main > main .da-sidebar {
		display: flex;
	}
}