@keyframes fadeIn {
	from {
		opacity: 0;
	}
	to {
		opacity: 100;
	}
}

.da-splash {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	position: absolute;
	z-index: 100;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: var(--white);
	opacity: 0;
	animation: fadeIn 350ms forwards;
}

.da-splash img {
	height: 20px;
}