.da-manage-page {
	padding: calc(40px + 2em) 0;
	height: 100%;
	flex: auto;
	overflow-y: auto;
	overflow-x: hidden;
}

.da-manage-page .da-environments {
	display: grid;
	gap: 2em;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
}