.da-env-page {
	display: flex;
	flex-direction: column;
	height: 100%;
	flex: auto;
	overflow-y: auto;
	padding-bottom: 40px;
	box-sizing: border-box;
	position: relative;
}
