.da-jobs {
	display: flex;
	flex-direction: column;
}

.da-jobs .da-jobs-options {
	display: flex;
	align-items: center;
	background-color: var(--white);
	padding: 12px;
	border-bottom: 1px solid var(--neutralLight);
	position: sticky;
	top: 40px;
	z-index: 1;
	box-shadow: 0 -1px 0 0 var(--white);
}

.da-jobs .da-jobs-options > * {
	margin-right: 1em;
}

.da-jobs .da-jobs-options > *:last-child {
	margin-right: 0;
}

.da-jobs .ms-Viewport .ms-DetailsHeader {
	padding-top: 1px;
}

.da-jobs .ms-Viewport .ms-DetailsRow {
	cursor: pointer;
}

.da-jobs .ms-DetailsRow-cell {
	display: flex;
	align-items: center;
}

.da-log {
	padding: 1em 0;
	border-bottom: 1px solid var(--neutralLight);
	display: flex;
	flex-direction: column;
	max-height: 100px;
	overflow-y: auto;
}

.da-log:last-child {
	border-bottom: none;
}

.da-log header {
	margin-bottom: 0.5em;
	font-weight: 500;
}