.da-access-denied {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	position: absolute;
	z-index: 100;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: var(--white);
}

.da-access-denied img {
	height: 20px;
}