.da-environment-card {
	flex: 1 1;
	box-shadow: 0 1.6px 3.6px 0 rgba(0,0,0,.132), 0 0.3px 0.9px 0 rgba(0,0,0,.108);
}

.da-environment-card a {
	text-decoration: none;
}

.da-environment-card header {
	cursor: pointer;
	font-size: 2em;
	padding: 1em;
	box-sizing: border-box;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 144px;
	background-color: var(--neutralSecondary);
	color: var(--neutralLighterAlt);
}

.da-environment-card header .ms-Icon {
	margin-right: 0.5em;
}

.da-environment-card .da-environment-card-details {
	padding: 0 1em;
}