.da-watermark-form {
  display: flex;
  flex-direction: column;
}

.da-watermark-btn {
  margin: 12px 0 0 auto;
}

.da-form-text {
  display: flex;
  justify-content: center;
}

.root-266::before {
  background-color: var(--neutralQuaternary);
}
