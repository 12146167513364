.da-command-bar {
  display: flex;
  align-items: center;
  border-bottom: 1px solid var(--neutralLight);
  background-color: var(--neutralLighter);
  min-height: 3.5em;
  width: 100%;
  min-width: 46.5em;
  margin-bottom: 2em;
  padding: 0 24px;
  box-sizing: border-box;
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  z-index: 2;
}

.da-command-bar > div:first-child {
  width: 27em;
}

.da-command-bar .ms-Breadcrumb {
  margin: 0;
  margin-right: 1em;
}

.da-command-bar .ms-Breadcrumb .ms-Breadcrumb-itemLink,
.da-command-bar .ms-Breadcrumb .ms-Breadcrumb-item {
  font-size: 1em;
}

@media screen and (max-width: 1025px) {
  .da-command-bar {
    min-height: 4em;
  }
}
