.da-dataset-list-options {
	display: flex;
	align-items: center;
	background-color: var(--white);
	padding: 12px;
	border-bottom: 1px solid var(--neutralLight);
	position: sticky;
	top: 40px;
	z-index: 1;
	box-shadow: 0 -1px 0 0 var(--white);
}
