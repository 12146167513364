:root {
  --themePrimary:         #f15a29;
  --themeLighterAlt:      #fef8f6;
  --themeLighter:         #fde4db;
  --themeLight:           #fbccbd;
  --themeTertiary:        #f79b7c;
  --themeSecondary:       #f46e42;
  --themeDarkAlt:         #da5225;
  --themeDark:            #b8461f;
  --themeDarker:          #883317;
  --neutralLighterAlt:    #faf9f8;
  --neutralLighter:       #f3f2f1;
  --neutralLight:         #edebe9;
  --neutralQuaternaryAlt: #e1dfdd;
  --neutralQuaternary:    #d0d0d0;
  --neutralTertiaryAlt:   #c8c6c4;
  --neutralTertiary:      #a19f9d;
  --neutralSecondary:     #605e5c;
  --neutralPrimaryAlt:    #3b3a39;
  --neutralPrimary:       #323130;
  --neutralDark:          #201f1e;
  --black:                #000000;
  --white:                #ffffff;
  --warning:              #ff2f2f;
  --green:                #1cb328;
}

html, body, #root {
  height: 100%;
  overflow: hidden;
  background-color: var(--neutralLighterAlt);
}

body {
  margin: 0;
  font-family: "Segoe UI", "Segoe UI Web (West European)", "Segoe UI", -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif;
  font-size: 88%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.da-general-loading {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100%;
	opacity: 0;
  animation: fadeIn 250ms 500ms ease-out forwards;
  flex: auto;
}
