.da-dwa {
  display: flex;
  flex-direction: column;
  height: 100%;
  flex: auto;
  box-sizing: border-box;
}

.da-panel {
  background-color: var(--white);
  padding: 24px 12px;
  box-shadow: 0 1.6px 3.6px 0 rgba(0, 0, 0, 0.132),
    0 0.3px 0.9px 0 rgba(0, 0, 0, 0.108);
}

.da-panel.settings {
  padding: 24px 0px;
  box-shadow: none;
}

.da-panel.settings .ms-MessageBar {
  margin-bottom: 15px;
}

.da-panel p {
  margin-top: 0;
  padding: 0 12px;
}

.da-panel form {
  padding: 0 12px;
}

.da-panel form > *:not(p) {
  margin-bottom: 0.5em;
}

.da-dataset-list {
  display: block;
  box-shadow: 0 1.6px 3.6px 0 rgba(0, 0, 0, 0.132),
    0 0.3px 0.9px 0 rgba(0, 0, 0, 0.108);
}

.da-dataset-list .ms-Viewport .ms-DetailsHeader {
  padding-top: 1px;
}

.da-dataset-list .ms-Viewport .ms-DetailsRow {
  cursor: pointer;
}

.da-dataset-list .ms-DetailsRow-cell {
  display: flex;
  align-items: center;
}

.da-dataset-list .ms-Toggle {
  margin-bottom: 0;
}
