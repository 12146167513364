.da-container {
  max-width: 74em;
  min-width: 47em;
  width: 100%;
  padding: 0 5vw;
  margin: 0 auto 2em auto;
}

.da-container > header {
  display: flex;
  flex-direction: row;
  margin-bottom: 1.5em;
  align-items: center;
}

.da-container > header h2 {
  font-weight: 500;
  flex: auto;
  margin: 0;
  display: flex;
  align-items: center;
}

.da-container > header h2 > * {
  font-size: 0.75em;
  margin-left: 1em;
}
